<script setup lang="ts">
import { saveAs } from 'file-saver'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

const props = defineProps(['thingModel'])
const message = useMessage()
const open = defineModel<boolean>('open')
function onSave() {
  const blob = new Blob([JSON.stringify(props.thingModel, null, 2)], {
    type: 'application/json',
  })
  saveAs(blob, `物模型.json`)
}

const copyText = async (text: string) => await navigator.clipboard.writeText(text)
async function onCopy() {
  await copyText(JSON.stringify(props.thingModel))
  message.success('已经复制到剪切板～')
}
</script>

<template>
  <a-modal v-model:open="open" title="物模型" :width="700">
    <div h-500px pos-relative>
      <VueJsonPretty :data="thingModel" :item-height="20" :height="500" :virtual="true" :show-icon="true" :show-double-quotes="false" />
    </div>
    <template #footer>
      <a-space>
        <a-button type="primary" @click="onCopy">
          复制
        </a-button>
        <a-button type="primary" @click="onSave">
          导出
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>
